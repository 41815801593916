<template>
  <div id="app">
    <!-- 分担区域 -->
    <el-container>
      <!-- 头部区域 -->
      <el-header>
        <div class="header_did">
          <div class="home_logo">
            <img src="../../public/css/zb47.png" alt>
            <div class="home_logo_text">云追查后台管理系统</div>
          </div>
          <div class="loginout">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link" style="cursor: pointer">
                {{ username }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>退出账号</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-container>
        <!-- 导航栏区域 -->
        <el-aside :width="collapse ? '64px' : '200px'" style="overflow-x:hidden;">
          <div class="collspce" @click="nav_collapse">{{list_title}}</div>
          <el-menu
            :default-active="list_path"
            class="el-menu-vertical-demo"
            background-color="#333"
            text-color="#fff"
            active-text-color="#0aa1ed"
            unique-opened
            router
            :collapse="collapse"
            :collapse-transition="false"
          >
            <el-submenu :index="item.wenidc_action_name" v-for="(item,t) in datas" :key="t">
              <template slot="title">
                <!-- el-icon-coin -->
                <i
                  :class="item.wenidc_id ==47 ?'el-icon-user-solid':item.wenidc_id==53 
                  ? 'el-icon-upload':item.wenidc_id==115 ? 'el-icon-s-comment':item.wenidc_id==135 
                  ? 'el-icon-s-tools': item.wenidc_id==141 ? 'el-icon-s-data': item.wenidc_id==153 
                  ? 'el-icon-warning':item.wenidc_id==177? 'el-icon-s-finance':item.wenidc_id==190 
                  ? 'el-icon-s-ticket':item.wenidc_id==249 ? 'el-icon-phone':item.wenidc_id==255 
                  ? 'el-icon-s-claim':item.wenidc_id==259 ? 'el-icon-office-building':item.wenidc_id==237 
                  ? 'el-icon-view': item.wenidc_id==315 ? 'el-icon-edit':item.wenidc_id==341 || item.wenidc_id==343
                  ? 'el-icon-s-custom' :'el-icon-more'"
                ></i>
                <el-badge :value="iconShow==true && item.wenidc_id==341 || item.wenidc_id==343 ? 'new' : ''">
                  <span>&nbsp;{{ item.wenidc_action_name }}</span>
                </el-badge>
              </template>
              <el-menu-item-group v-for="son of item.wenidc_son" :key="son.id">
                <el-menu-item
                  style="padding-left:50px;"
                  :index="son.wenidc_action_url"
                  @click="son_path(son.wenidc_action_url)"
                >&nbsp; {{ son.wenidc_action_name }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-aside>
        <!-- 主体区域 -->
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 模拟数据
      datas: [],
      iconShow:this.$store.state.newShow,
      // 是否折叠
      collapse: false,
      // 登录用户的名称
      username: "",
      // 默认选中
      list_path: "",
      list_title: "展开",
      // 窗口大小
      offset: document.body.clientWidth
    };
  },
  watch: {
    $route(to,from){
      if(to.fullpath!=='/chat'){
        this.$store.commit('setIcon',true)
        this.$store.commit('setRe',true)
      }
    },
    offset: {
      handler(newVal) {
        if (newVal < 500) {
          this.collapse = true;
          this.list_title = "收起";
        } else {
          this.collapse = false;
          this.list_title = "展开";
          this.$store.commit("store_add_width", [
            this.offset - 280 + "px",
            this.offset - 258 + "px",
            this.offset - 265 + "px"
          ]);
        }
      }
    }
  },
  // 拿到token
  mounted() {
    let that = this;
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.offset = window.screenWidth;
      })();
    });
    this.$store.commit("store_add_width", [
      this.offset - 280 + "px",
      this.offset - 258 + "px",
      this.offset - 265 + "px"
    ]);
    // 获取列表
    this.get_home_list();
    this.list_path = window.localStorage.getItem("user_path");
    this.username = JSON.parse(window.sessionStorage.getItem("info")).username;
  },

  methods: {
    // 拿到导航栏数据
    get_home_list() {
      this.datas = JSON.parse(window.sessionStorage.getItem("bar"));
      // console.log(this.datas)
    },
    // 存储路径
    son_path(e) {
      this.list_path = e;
      window.localStorage.setItem("user_path", e);
    },

    nav_collapse() {
      if (this.collapse == true) {
        this.collapse = !this.collapse;
        this.list_title = "收起";
        //设置页面头部的面包屑，跟随页面宽度变化
        this.$store.commit("store_add_width", [
          this.offset - 280 + "px",
          this.offset - 258 + "px",
          this.offset - 265 + "px"
        ]);
      } else {
        this.collapse = !this.collapse;
        this.list_title = "展开";
        this.$store.commit("store_add_width", [
          this.offset - 144 + "px",
          this.offset - 122 + "px",
          this.offset - 129 + "px"
        ]);
      }
    },
    // 退出登录
    handleCommand() {
      window.localStorage.clear();
      window.sessionStorage.clear();
      this.$router.push("/login");
    }
  }
};
</script>
<style scoped>
.el-header {
  background-color: #fff !important;
}
.header_did {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.home_logo {
  height: 48px;
  padding: 5px;
  display: flex;
  align-items: center;
}
.home_logo_text {
  color: #333;
  font-size: 16px;
}
.home_logo > img {
  height: 100%;
  margin-right: 20px;
}
.el-menu-item {
  border-right: 0 !important;
}
.el-container {
  height: 100%;
}
.el-header {
  background-color: #eaeaea;
}
.el-aside {
  font-size: 14px;
  background-color: #333;
}
.collspce {
  color: #fff;
  display: flex;
  justify-content: center;
  background-color: #666;
  padding: 10px 0;
  color: #f8f8f8;
  font-weight: 700;
  cursor: pointer;
}
@media screen and (max-width: 550px) {
  .home_logo_text {
    display: none;
  }
}
</style>